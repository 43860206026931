import * as Sentry from "@sentry/remix";

export const LogError = (...err) => {
  if (import.meta?.env?.DEV) {
    console.error("error: ", ...err, "( in dev mode)");
  } else {
    console.error(...err);
    const errors = Array.isArray(err) ? err : [err];
    const errorLogs = [];
    for (let err of errors) {
      errorLogs.push(err);
    }
    Sentry.captureException(errorLogs);
  }
};
